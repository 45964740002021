<template>
  <div>
    <v-tabs right v-model="tab" @change="saveLastActiveTab">
      <v-tab key="fire-tab">Loot - Feuer</v-tab>
      <v-tab key="flame-tab">Loot - Flamme</v-tab>
      <v-tab key="management-tab">{{ $t('general.misc.history') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item key="fire-tab" class="ma-2">
        <loot-table :roster="'fire'" :use-wishlists="true"></loot-table>
      </v-tab-item>
      <v-tab-item key="flame-tab" class="ma-2">
        <loot-table :roster="'flame'" :use-wishlists="false"></loot-table>
      </v-tab-item>
      <v-tab-item key="management-tab" class="ma-2">
        <v-row class="ma-2 align-center mt-2">
          <v-autocomplete label="Loot Episode" v-model="lootEpisode" :items="lootEpisodes" hide-details return-object></v-autocomplete>
          <v-btn class="mt-2 mb-2 ml-2" @click="isOpenNewLootEntry = true" color="primary" v-if="manageLoot" :disabled="isLoading">
            <v-icon>mdi-plus-circle</v-icon>
            {{ $t('loot.management.new_entry.title') }}
          </v-btn>
        </v-row>
        <v-expansion-panels class="mt-3">
          <v-expansion-panel v-for="raid in filterSavedRaids" :key="raid.id" class="secondary lighten-1">
            <v-expansion-panel-header>
              <h3>
                <v-icon>mdi-sword-cross</v-icon>
                {{ raid.instance }} ({{ raid.difficulty }}) - {{ raid.date }}
                <v-chip label color="primary" outlined v-if="raid.roster === 'fire'" class="ml-2">
                  <v-icon left>mdi-fire</v-icon>
                  Feuer
                </v-chip>
                <v-chip label class="ml-2" color="#298F85" outlined v-if="raid.roster === 'flame'">
                  <v-icon left>mdi-fire</v-icon>
                  Flamme
                </v-chip>
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <h3 class="pa-2">{{ raid.characters.length }} {{ $t('general.misc.member') }}</h3>
              <v-divider></v-divider>
              <group-setup :entries="raid.characters"
                           :addition-allowed="false" :selection-change-allowed="false" v-if="raid.characters.length > 0"></group-setup>
              <v-divider class="mt-1 mb-1"></v-divider>
              <h3 class="pa-2">{{ raid.items.length }} {{ $t('general.misc.items') }}</h3>
              <v-divider></v-divider>
              <v-row class="mt-2 ml-2 pb-1 align-center" v-for="item of raid.items" v-bind:key="item.id">
                <div class="mr-3" style="width: 40px; display: inline-block; text-align: center" v-if="item.visibleItem">
                  <a v-bind:href="'https://www.wowhead.com/item=' + item.itemId" :data-wowhead="EquipmentHelper().getItemContext(item, $i18n.locale)"
                     target="_blank"><img class="raid-item" v-bind:src="item.media" alt="raid-item"></a>
                </div>
                <p class="pb-2 mb-1" v-if="item.visibleItem">
                  <class-avatar v-bind:type="'class'" v-bind:value="item.character.class.toLowerCase()" v-bind:size="20"
                                class="mr-2"/>
                  <span class="font-weight-medium"
                        :class="item.character.class.toLowerCase().replaceAll(' ', '-')">{{ item.character.name }}</span>
                  <span> | <i>{{ item.choice }}</i></span>
                </p>
              </v-row>
              <v-row class="pa-2 mt-2 justify-end">
                <v-btn color="primary" @click="openDeleteConfirmation(raid)" v-if="manageLoot">
                  <v-icon>mdi-delete</v-icon>
                  {{ $t('general.actions.delete') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <h4 v-if="!isLoading && filterSavedRaids.length === 0" class="mt-2">
          <v-icon>mdi-close-circle</v-icon>
          {{ $t('loot.no_saved_raids') }}
        </h4>
        <v-row class="align-center ma-3" v-if="isLoading">
          <v-progress-circular class="align-center" color="primary" indeterminate :size="50"
                               :width="5"></v-progress-circular>
        </v-row>

        <v-dialog v-model="isOpenNewLootEntry" persistent max-width="1100px">
          <v-card class="planner-new-raid">
            <v-card-title>
              <span class="text-h5">{{ $t('loot.management.new_entry.title') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="pa-2">
                  <v-radio-group v-model="filterRaids" row>
                    <v-radio :label="$t('loot.management.creation_interval.two_weeks')" value="2-week" key="0"></v-radio>
                    <v-radio :label="$t('loot.management.creation_interval.month')" value="month" key="1"></v-radio>
                    <v-radio :label="$t('loot.management.creation_interval.all')" value="all" key="2"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="ma-1">
                  <v-autocomplete :label="$t('general.misc.roster')" v-model="importData.roster"
                              :items="rosterOptions" @change="selectPlannedRaidRoster"
                              item-text="description" return-object class="mr-2"></v-autocomplete>
                  <v-autocomplete :label="$t('loot.management.new_entry.planned_raid')" v-model="importData.plannedRaid"
                              :items="filterPlannedRaids(importData.roster)"
                              @change="selectPlannedRaid(importData.roster)"
                              item-text="name" return-object></v-autocomplete>
                </v-row>
                <v-row class="ma-1 justify-center align-center"
                       v-if="importData.plannedRaid !== null && !importData.plannedRaid.detailsLoaded">
                  <v-progress-circular :size="50" color="primary" class="pa-4" indeterminate></v-progress-circular>
                </v-row>
                <group-setup v-if="importData.plannedRaid !== null && importData.plannedRaid.detailsLoaded" class="ma-1"
                  :event="importData.plannedRaid" :addition-allowed="true" :selection-change-allowed="true">
                </group-setup>
                <v-row class="ma-1">
                  <v-textarea class="mt-2" name="lootData" v-model="importData.lootData" filled no-resize
                              :label="$t('loot.management.new_entry.loot_data')"></v-textarea>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn @click="isOpenNewLootEntry = false" class="mb-1" :disabled="this.importData.saving">
                {{ $t('general.actions.cancel') }}
              </v-btn>
              <v-btn color="primary" @click="saveLootEntry" class="mr-1 mb-1"
                     :disabled="this.importData.plannedRaid === null || !this.importData.plannedRaid.detailsLoaded || this.importData.saving
                     || this.importData.plannedRaid.detailsLoaded && this.importData.plannedRaid.details.signups.length === 0">
                {{ $t('general.actions.save') }}
              </v-btn>
              <div v-if="this.importData.saving">
                <v-progress-circular class="align-center" color="primary" indeterminate :size="30"
                                     :width="2"></v-progress-circular>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="isOpenDeleteConfirmation" persistent max-width="500px">
          <v-card class="planner-new-raid">
            <v-card-title>
              <span class="text-h5"> {{ $t('general.misc.confirmation') }}</span>
            </v-card-title>
            <v-card-text>
              <p>{{ $t('loot.management.delete_confirmation') }}</p>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn @click="isOpenDeleteConfirmation = false" class="mb-1">
                {{ $t('general.actions.cancel') }}
              </v-btn>
              <v-btn color="primary" @click="deleteRaid" class="mr-1 mb-1">
                {{ $t('general.actions.delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ExternalApiService from "@/services/wow_audit.service";
import ClassAvatar from "@/components/ClassAvatar";
import RaidService from "@/services/raid.service";
import MessageService from "@/services/message.service";
import LootTable from "@/components/LootTable";
import Constants from "@/util/constants";
import GroupSetup from "@/components/GroupSetup";
import DataService from "@/services/data.service";
import EquipmentHelper from "@/util/EquipmentHelper";

export default {
  name: "LootManagement",
  components: {GroupSetup, ClassAvatar, LootTable},
  data() {
    return {
      tab: 0,
      isLoading: true,
      importData: {
        lootData: "",
        roster: null,
        plannedRaid: null,
        character: null,
        role: "Tank",
        saving: false
      },
      isOpenDeleteConfirmation: false,
      raidToDelete: null,
      plannedRaidsFire: [],
      plannedRaidsFlame: [],
      savedRaids: [],
      currentInstanceList: [],
      currentInstanceData: {},
      encounters: [],
      isOpenNewLootEntry: false,
      rosterOptions: [Constants.ROSTER_INFO.fire, Constants.ROSTER_INFO.flame],
      raidDurationList: ["all", "short"],
      filterRaids: "2-week",
      wishlistsLoaded: false,
      wishlistsLoading: true,
      wishlistData: {},
      characters: [],
      difficulties: [],
      difficulty: null,
      lootEpisodes: [],
      lootEpisode: ""
    }
  },
  async mounted() {
    let tabItem = localStorage.getItem("loot_management_tab");
    if (tabItem) {
      this.tab = parseInt(tabItem);
    } else {
      localStorage.setItem("loot_management_tab", this.tab);
    }

    this.initializeConfigs();

    this.plannedRaidsFire = await this.initializeRaids("fire");
    this.plannedRaidsFlame = await this.initializeRaids("flame");

    let raidResult = await RaidService.getRaidEntries("all");
    if(raidResult){
      this.savedRaids = raidResult.map(r => ({
        ...r,
        date: new Date(r.timestamp).toLocaleDateString('de-de'),
        characters: r.characters.map(c => ({
          ...c,
          character: {
            name: c.name
          },
          role: c.character_raids.role ? c.character_raids.role : c.defaultRole,
          selected: true
        })),
        items: r.items.sort((b, a) => b.characterId - a.characterId)
      })).sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    this.loadWishlistData(false);

    if(this.importData.roster === null){
      this.importData.roster = this.rosterOptions[0];
    }
    this.isLoading = false;
  },
  methods: {
    async initializeRaids(roster){
      let wowAuditRaids = await ExternalApiService.getWowAuditRaids(roster);
      if(wowAuditRaids){
        return wowAuditRaids.map(r => ({
          ...r,
          name: `${r.instance} (${r.difficulty}) - ${r.date}`,
          detailsLoaded: false,
          type: roster,
          details: {
            signups: []
          },
          saving: false
        }));
      }

      return [];
    },
    initializeConfigs(){
      let appConfig = this.$store.getters["data/appConfig"];

      let difficulties = appConfig.find(x => x.key === "DIFFICULTIES");
      let currentDifficulty = appConfig.find(x => x.key === "CURRENT_DIFFICULTY");
      if (difficulties && currentDifficulty) {
        this.difficulties = difficulties.value.split(";");
        this.difficulty = currentDifficulty.value;
      }

      let currentInstances = appConfig.find(x => x.key === "CURRENT_INSTANCES");
      if (currentInstances) {
        this.currentInstanceData = {};
        this.currentInstanceList = currentInstances.value.split(";");
        this.currentInstanceList.forEach(instance => {
          DataService.getInstance(instance).then(data => {
            this.currentInstanceData[data.name] = data;
            this.encounters = this.encounters.concat(data.encounters.map(x => ({
              name: x.name,
              instance: data.name
            })));
          });
        });
      } else {
        MessageService.error("Current instances could not be loaded properly");
      }

      let lootEpisodes = appConfig.find(x => x.key === "LOOT_EPISODES");
      if (lootEpisodes) {
        let episodeValues = lootEpisodes.value.split(";");
        episodeValues = episodeValues.sort((a, b) => a.localeCompare(b));
        episodeValues.forEach(value => {
          this.lootEpisodes.push(value);
        });
      }

      let currentEpisode = appConfig.find(x => x.key === "CURRENT_LOOT_EPISODE");
      if (currentEpisode) {
        this.lootEpisode = currentEpisode.value;
      }

      if(this.lootEpisode === ""){
        this.lootEpisode = this.lootEpisodes[0];
      }
    },
    EquipmentHelper() {
      return EquipmentHelper
    },
    loadWishlistData(force){
      this.wishlistsLoading = true;
      ExternalApiService.getWowAuditWishlists("fire", force).then(wishlistData => {
        ExternalApiService.getWowAuditCharacters("fire").then(characterData => {
          this.characters = characterData;
          this.wishlistData = wishlistData;

          this.wishlistsLoading = false;
          this.wishlistsLoaded = true;
        });
      });
    },
    changeDifficulty() {
      if (this.difficulty === null) {
        this.difficulty = this.difficulties[0];
      }
    },
    itemCount(encounter) {
      if (encounter && encounter.items && encounter.items.length > 0) {
        return encounter.items.length;
      } else {
        return 0;
      }
    },
    getInstanceItemContext(item) {
      let difficultyBonusId = {
        "normal": 0,
        "heroic": 6605,
        "mythic": 6606
      }

      let instanceItem = this.currentInstanceData.instance_items.find(x => x.itemId === item.id);
      if (instanceItem) {
        let bonusId = difficultyBonusId[this.difficulty.toLowerCase()];
        let itemLevel = instanceItem[this.difficulty.toLowerCase()];

        return `domain=${this.$i18n.locale}&bonus=${bonusId}&ilvl=${itemLevel}`;
      }
      return "";
    },
    saveLastActiveTab() {
      localStorage.setItem("loot_management_tab", this.tab);
    },
    filterPlannedRaids(roster) {
      if(roster !== null){
        let now = new Date();
        now.setHours(23);
        now.setMinutes(59);
        let lastDate;

        if(this.filterRaids === "2-week") {
          lastDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14, 23, 59);
        } else if(this.filterRaids === "month"){
          lastDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 23, 59);
        } else {
          lastDate = new Date(now.getFullYear()-2, now.getMonth(), now.getDate(), 23, 59);
        }

        if (roster.value === Constants.ROSTER_INFO.fire.value) {
          return this.plannedRaidsFire.filter(x => new Date(`${x["date"]} ${x["start_time"]}`) >= lastDate && new Date(`${x["date"]} ${x["start_time"]}`) <= now && !this.savedRaids.find(y => y.raidId.toString() === x.id.toString()))
              .sort((a, b) => new Date(`${b["date"]} ${b["start_time"]}`) - new Date(`${a["date"]} ${a["start_time"]}`));
        } else if (roster.value === Constants.ROSTER_INFO.flame.value) {
          return this.plannedRaidsFlame.filter(x => new Date(`${x["date"]} ${x["start_time"]}`) >= lastDate && new Date(`${x["date"]} ${x["start_time"]}`) <= now && !this.savedRaids.find(y => y.raidId.toString() === x.id.toString()))
              .sort((a, b) => new Date(`${b["date"]} ${b["start_time"]}`) - new Date(`${a["date"]} ${a["start_time"]}`));
        } else {
          return [];
        }
      } else {
        return [];
      }

    },
    selectPlannedRaidRoster(){
      this.importData.plannedRaid = null;
    },
    async selectPlannedRaid(roster) {
      if (!this.importData.plannedRaid.detailsLoaded) {
        this.importData.plannedRaid.details = await ExternalApiService.getWowAuditRaidDetails(roster.value, this.importData.plannedRaid.id);
        this.importData.plannedRaid.details.signups.map(s => ({
          ...s,
          selected: s.status === "Present"
        }));
        this.importData.plannedRaid.detailsLoaded = true;
        this.$root.$emit("updateEvent", this.importData.plannedRaid);
      } else {
        this.$root.$emit("updateEvent", this.importData.plannedRaid);
      }
    },
    async saveLootEntry() {
      this.importData.saving = true;

      let signups = this.importData.plannedRaid.details.signups.filter(x => x.selected).map(x => ({
        ...x,
        fromName: true
      }));

      let lootData;

      try {
        lootData = JSON.parse(this.importData.lootData);
      } catch (e) {
        lootData = []
      }

      let roster = this.importData.roster.value;
      RaidService.createRaidEntry(roster, this.importData.plannedRaid.details, signups, lootData).then(data => {
        if(data !== null){
          this.importData = {
            roster: Constants.ROSTER_INFO.fire,
            plannedRaid: null,
            lootData: "",
            saving: false
          }
          this.isOpenNewLootEntry = false;

          data.date = new Date(data["timestamp"]).toLocaleDateString('de-de');
          data.characters =  data.characters.map(c => ({
            ...c,
            character: {
              name: c.name
            },
            role: c.character_raids.role ? c.character_raids.role : c.defaultRole,
            selected: true
          }))

          this.savedRaids.unshift(data);
          this.$root.$emit(`raidUpdate_${roster}`);
        }
      });
    },
    openDeleteConfirmation(raid) {
      this.raidToDelete = raid;
      this.isOpenDeleteConfirmation = true;
    },
    deleteRaid() {
      this.isOpenDeleteConfirmation = false;
      let roster = this.raidToDelete.roster;
      RaidService.deleteRaidEntry(this.raidToDelete.id).then(async data => {
        if (data) {
          MessageService.success("Successfully deleted raid");
          let index = this.savedRaids.indexOf(this.raidToDelete);
          this.savedRaids.splice(index, 1);
          this.$root.$emit(`raidUpdate_${roster}`);
        }
      });
    }
  },
  computed: {
    filterSavedRaids(){
      return this.savedRaids.filter(r => r.lootEpisode === this.lootEpisode).sort((a, b) => new Date(b.start) - new Date(a.start));
    },
    manageLoot() {
      return this.$store.state.auth.user.rights.includes("EDIT_LOOT_DATA");
    }
  }
}
</script>

<style scoped lang="scss">

</style>