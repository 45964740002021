<template>
  <div>
    <div v-if="lootEntry.isTemporary">
      <h3 class="pa-2">
        <v-icon class="mr-1">mdi-close-circle</v-icon>
        {{ $t('loot.no_details')}}
      </h3>
    </div>
    <div v-else>
      <div v-if="lootEntry && lootEntry.subEntries.length > 1">
        <v-tabs right v-model="tab">
          <v-tab key="details">Details</v-tab>
          <v-tab key="wishlist" :disabled="!useWishlist">Wishlist</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="details">
            <v-list two-line>
              <v-list-item class="mt-2" :class="`${character.class.toLowerCase().replaceAll(' ', '-')}-bg`"
                           v-for="character of getSubEntries" :key="character.id">
                <class-avatar :size="40" :type="'class'" :value="character.class.toLowerCase()" class="mr-3"></class-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b :class="character.class.toLowerCase().replaceAll(' ', '-')">{{ character.name }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{character.state}}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <h3 class="mt-1">{{ $t('loot.table.attendance') }} - {{character.attendanceCount}} Raids ({{character.attendancePercent}} %)</h3>
                    <v-divider vertical class="mx-4"></v-divider>
                    <h3 class="mt-1 mr-4">Items - {{character.itemsCount}}</h3>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item key="wishlist">
            <v-btn-toggle v-model="selectedCharacter" borderless mandatory>
              <v-btn :value="entry" v-for="entry in lootEntry.subEntries" :key="entry.id">
                <class-avatar v-bind:type="'class'" v-bind:value="entry.class.toLowerCase()" v-bind:size="30"/>
                <span class="ml-2" :class="entry.class.toLowerCase().replaceAll(' ', '-')"><b>{{ entry.name }}</b></span>
              </v-btn>
            </v-btn-toggle>

            <div v-if="encounter.name === 'All' && selectedCharacter?.currentWishlist?.length === 0" class="mt-3">
              <h3 class="pa-2">
                <v-icon class="mr-1">mdi-close-circle</v-icon>
                {{ $t('loot.wishlist.no_wishes') }}
              </h3>
            </div>
            <div v-if="encounter.name === 'All'" class="mt-3">
              <div class="loot-grid">
                <v-card class="secondary lighten-1 mb-2 fill-height" v-for="wishlistEncounter in selectedCharacter.currentWishlist" :key="wishlistEncounter.name">
                  <v-card-subtitle>
                    <p class="mb-0 pb-0">
                      <b>{{ wishlistEncounter.name }}</b>
                    </p>
                    <p class="mb-0 pb-0">
                      <i>{{wishlistEncounter.instanceName}}</i>
                    </p>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row class="pa-2 mt-2 ml-1 align-center" v-for="loot in wishlistEncounter.items" :key="loot.id">
                      <div class="mr-3 " style="display: inline-block; text-align: center">
                        <a v-bind:href="'https://www.wowhead.com/item=' + loot.id"
                           :data-wowhead="getInstanceItemContext(loot)"
                           target="_blank"><img class="raid-item" v-bind:src="loot.media" alt="loot_item"></a>
                      </div>
                      <p class="pb-1 mb-0">
                    <span v-for="wish in loot['wishes']" :key="wish.specialization" class="mr-3">
                      <specialization-avatar class="mr-1" :size="20" :value="wish.specialization.toLowerCase()"
                                             :type="selectedCharacter.class.toLowerCase()"></specialization-avatar>
                      <span v-if="wish['manually_edited']" class="upgrade" :class="wish.upgrade">
                        <b><i>{{ wish.upgrade?.toUpperCase() }}</i></b>
                      </span>
                      <span v-else>
                         <b><i>{{ Math.round(wish.absolute) }} DPS ({{ wish.percentage.toFixed(2) }} %)</i></b>
                      </span>
                      <span v-if="wish.comment" class="ml-2" style="color:grey;">
                        - <v-icon small color="grey">mdi-comment</v-icon> {{ wish.comment }}
                      </span>
                    </span>
                      </p>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div v-else  class="mt-2">
              <div v-if="filterEncounterItems.length === 0">
                <h3 class="pa-2">
                  <v-icon class="mr-1">mdi-close-circle</v-icon>
                  {{ $t('loot.wishlist.no_wishes') }}
                </h3>
              </div>
              <v-row class="pa-3 ml-2 align-center" v-for="loot in filterEncounterItems"
                     :key="loot.id">
                <div class="mr-3 " style="display: inline-block; text-align: center">
                  <a v-bind:href="'https://www.wowhead.com/item=' + loot.id"
                     :data-wowhead="getInstanceItemContext(loot)"
                     target="_blank"><img class="raid-item" v-bind:src="loot.media" alt="loot_item"></a>
                </div>
                <p class="pb-1 mb-0">
              <span v-for="wish in loot['wishes']" :key="wish.specialization" class="mr-3">
                <specialization-avatar class="mr-1" :size="20" :value="wish.specialization.toLowerCase()"
                                       :type="selectedCharacter.class.toLowerCase()"></specialization-avatar>
                <span v-if="wish['wmanually_edited']" class="upgrade" :class="wish.upgrade">
                  <b><i>{{ wish.upgrade?.toUpperCase() }}</i></b>
                </span>
                <span v-else>
                   <b><i>{{ Math.round(wish.absolute) }} DPS ({{ wish.percentage.toFixed(2) }} %)</i></b>
                </span>
                <span v-if="wish.comment" class="ml-2" style="color:grey;">
                  - <v-icon small color="grey">mdi-comment</v-icon> {{ wish.comment }}
                </span>
              </span>
                </p>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-else>
        <div v-if="encounter.name === 'All' && selectedCharacter?.currentWishlist?.length === 0">
          <h3 class="pa-2">
            <v-icon class="mr-1">mdi-close-circle</v-icon>
            {{ $t('loot.wishlist.no_wishes') }}
          </h3>
        </div>
        <div v-if="encounter.name === 'All'" class="mt-3">
          <div class="loot-grid">
            <v-card class="secondary lighten-1 mb-2 fill-height" v-for="wishlistEncounter in selectedCharacter.currentWishlist" :key="wishlistEncounter.name">
              <v-card-subtitle>
                <p class="mb-0 pb-0">
                  <b>{{ wishlistEncounter.name }}</b>
                </p>
                <p class="mb-0 pb-0">
                  <i>{{wishlistEncounter.instanceName}}</i>
                </p>
              </v-card-subtitle>
              <v-card-text>
                <v-row class="pa-2 mt-2 ml-1 align-center" v-for="loot in wishlistEncounter.items" :key="loot.id">
                  <div class="mr-3 " style="display: inline-block; text-align: center">
                    <a v-bind:href="'https://www.wowhead.com/item=' + loot.id"
                       :data-wowhead="getInstanceItemContext(loot)"
                       target="_blank"><img class="raid-item" v-bind:src="loot.media" alt="loot_item"></a>
                  </div>
                  <p class="pb-1 mb-0">
                    <span v-for="wish in loot['wishes']" :key="wish.specialization" class="mr-3">
                      <specialization-avatar class="mr-1" :size="20" :value="wish.specialization.toLowerCase()"
                                             :type="selectedCharacter.class.toLowerCase()"></specialization-avatar>
                      <span v-if="wish['manually_edited']" class="upgrade" :class="wish.upgrade">
                        <b><i>{{ wish.upgrade?.toUpperCase() }}</i></b>
                      </span>
                      <span v-else>
                         <b><i>{{ Math.round(wish.absolute) }} DPS ({{ wish.percentage.toFixed(2) }} %)</i></b>
                      </span>
                      <span v-if="wish.comment" class="ml-2" style="color:grey;">
                        - <v-icon small color="grey">mdi-comment</v-icon> {{ wish.comment }}
                      </span>
                    </span>
                  </p>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div v-else>
          <div v-if="filterEncounterItems.length === 0">
            <h3 class="pa-2">
              <v-icon class="mr-1">mdi-close-circle</v-icon>
              {{ $t('loot.wishlist.no_wishes') }}
            </h3>
          </div>
          <v-row class="pa-3 ml-2 align-center" v-for="loot in filterEncounterItems"
                 :key="loot.id">
            <div class="mr-3 " style="display: inline-block; text-align: center">
              <a v-bind:href="'https://www.wowhead.com/item=' + loot.id"
                 :data-wowhead="getInstanceItemContext(loot)"
                 target="_blank"><img class="raid-item" v-bind:src="loot.media" alt="loot_item"></a>
            </div>
            <p class="pb-1 mb-0">
              <span v-for="wish in loot['wishes']" :key="wish.specialization" class="mr-3">
                <specialization-avatar class="mr-1" :size="20" :value="wish.specialization.toLowerCase()"
                                       :type="selectedCharacter.class.toLowerCase()"></specialization-avatar>
                <span v-if="wish['manually_edited']" class="upgrade" :class="wish.upgrade">
                  <b><i>{{ wish.upgrade?.toUpperCase() }}</i></b>
                </span>
                <span v-else>
                   <b><i>{{ Math.round(wish.absolute) }} DPS ({{ wish.percentage.toFixed(2) }} %)</i></b>
                </span>
                <span v-if="wish.comment" class="ml-2" style="color:grey;">
                  - <v-icon small color="grey">mdi-comment</v-icon> {{ wish.comment }}
                </span>
              </span>
            </p>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpecializationAvatar from "@/components/SpecializationAvatar.vue";
import ClassAvatar from "@/components/ClassAvatar.vue";

export default {
  name: "LootDetailView",
  components: {ClassAvatar, SpecializationAvatar},
  props: ['lootMasterMode', "useWishlist", "wishlists", "lootEntry", "instanceList",
    "encounter", "currentInstanceData", "difficulty", "roster"],
  data() {
    return {
      tab: 0,
      selectedCharacter: this.lootEntry,
      wishlistColumnSize: 4,
      wishlistData: [],
      instanceItems: [],
      currentWishlistData: {},
      headers: [
        {text: '', value: "class", width: "20px", simple: true },
        {text: this.$t('general.misc.name'), value: "name", simple: true},
        {text: this.$t('loot.table.state'), value: "state", simple: true},
        {
          text: this.$t('loot.table.attendance'),
          value: "attendanceCount",
          lootMasterMode: false,
          align: 'center',
          simple: true
        },
        {
          text: this.$t('loot.table.attendance_percent'),
          value: "attendancePercent",
          lootMasterMode: false,
          align: 'center',
          simple: true
        },
        {
          text: this.$t('loot.table.items'),
          value: "itemsCount",
          lootMasterMode: false,
          tooltip: this.$t('loot.table.items_tooltip'),
          align: 'center',
          simple: true
        }
      ]
    }
  },
  mounted() {
    if(this.lootMasterMode && this.useWishlist){
      this.tab = 1;
    }

    Object.keys(this.currentInstanceData).forEach(key => {
      let instanceVal = this.currentInstanceData[key];
      this.instanceItems = this.instanceItems.concat(instanceVal.instance_items.map(x => ({
        ...x,
        instanceName: key
      })));
    });

    if(this.useWishlist && Object.keys(this.wishlists).length > 0){
      this.lootEntry.subEntries.forEach(subEntry => {
        subEntry.wishlists = this.useWishlist && Object.keys(this.wishlists).length > 0 ? this.wishlists[subEntry.rosterInformation[this.roster]?.id] : [];
        subEntry.currentWishlist = this.useWishlist && Object.keys(this.wishlists).length > 0 ? this.getInstanceWishlist(subEntry.name, this.wishlists[subEntry.rosterInformation[this.roster]?.id]) : {};
      });
    }

    this.selectedCharacter = this.lootEntry.subEntries.find(x => x.isMainEntry);
  },
  methods: {
    itemCount(encounter) {
      if (encounter && encounter.items && encounter.items.length > 0) {
        return encounter.items.length;
      } else {
        return 0;
      }
    },
    getInstanceItemContext(item) {
      let difficultyBonusId = {
        "normal": 0,
        "heroic": 6605,
        "mythic": 6606
      }

      let instanceItem = this.instanceItems.find(x => x.itemId === item.id);
      if (instanceItem) {
        let bonusId = difficultyBonusId[this.difficulty.toLowerCase()];
        let itemLevel = instanceItem[this.difficulty.toLowerCase()];

        return `domain=${this.$i18n.locale}&bonus=${bonusId}&ilvl=${itemLevel}`;
      }
      return "";
    },
    getInstanceWishlist(character, wishlists) {
      let encounterList = [];
      if (wishlists) {
        this.instanceList.forEach(instance => {
          let wishlist = wishlists.find(x => x.name === instance);
          if (wishlist) {
            let encounters = wishlist.difficulties[this.difficulty];
            if (encounters) {
              encounterList = encounterList.concat(encounters.filter(x => x.items.length > 0).map(x => ({
                ...x,
                instanceName: instance
              })));
            }
          }
        });
      }

      return encounterList;
    }
  },
  computed: {
    getSubEntries() {
      return this.lootEntry.subEntries;
    },
    filterEncounterItems() {
      if(this.selectedCharacter.currentWishlist){
        let found = this.selectedCharacter.currentWishlist.find(x => x.name === this.encounter.name);
        if (found) {
          return found.items;
        }
      }

      return [];
    },
  },
  watch: {
    difficulty(){
      this.lootEntry.subEntries.forEach(lootEntry => {
        lootEntry.currentWishlist = this.getInstanceWishlist(lootEntry.name, lootEntry.wishlists);
      });
    }
  }
}
</script>


<style lang="scss">
.loot-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
</style>