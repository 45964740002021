<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list-item>
        <v-list-item-content class="py-1">
          <v-list-item-title class="text-center">
            <v-btn-toggle v-model="$i18n.locale" tile group color="primary" class="mb-1">
              <v-btn :value="lang" v-for="lang in $i18n.availableLocales" :key="lang">
                <country-flag :country='getFlag(lang)' size='normal' class="mb-0"/>
              </v-btn>
            </v-btn-toggle>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <div v-for="item in menuItems" :key="item.title">
          <v-list-item link :to="item.to" v-on="item.action ? { click: item.action } : {}" v-if="item.show" :color="item.color">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-divider></v-divider>
      <div v-if="showManagement || showAdminPanel">
        <v-divider></v-divider>
        <v-list dense nav>
          <div v-for="item in managementItems" :key="item.title">
            <v-list-item link :to="item.to" v-on="item.action ? { click: item.action } : {}" v-if="item.show" :color="item.color">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-divider></v-divider>
      </div>
      <div v-if="currentUser">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6" color="primary">
              <v-icon>mdi-account</v-icon>
              {{ currentUser.data.username }}
              <v-btn v-if="hasOriginalId" icon @click="signInAsRestore">
                <v-icon>mdi-arrow-left-circle</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense nav>
          <div v-for="item in userItems" :key="item.title">
            <v-list-item link :to="item.to" v-on="item.action ? { click: item.action } : {}" v-if="item.show">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </div>
      <v-divider></v-divider>
    </v-navigation-drawer>
    <v-app-bar app color="secondary" class="hidden-lg-and-up">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center" style="cursor:pointer;" @click="toHomeView">
        <img src="./assets/logo.png" width="60" alt="logo" />
        <h3 class="ml-2">{{ title }}</h3>
      </div>
    </v-app-bar>
    <v-app-bar app color="secondary" class="hidden-md-and-down" >
      <div class="d-flex align-center" style="cursor:pointer;" @click="toHomeView">
        <img src="./assets/logo.png" width="60" alt="logo"/>
        <h3 class="ml-2">{{ title }}</h3>
      </div>
      <v-spacer></v-spacer>
      <div v-for="item in menuItems" :key="item.title" class="mr-1">
        <v-btn :color="item.color" text v-if="item.show" v-on="item.action ? { click: item.action } : {}" :to="item.to">
          <v-icon class="mr-1">{{ item.icon }}</v-icon>
          <span>{{ $t(item.title) }}</span>
        </v-btn>
      </div>
      <div v-if="currentUser">
          <v-menu open-on-click offset-y bottom v-if="showManagement || showAdminPanel">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon class="mr-1">mdi-cogs</v-icon>
                {{ $t("management.title") }}
                <v-icon class="mr-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <div v-for="item in managementItems" :key="item.title">
                <v-list-item v-if="item.show">
                  <v-btn text :to="item.to" v-on="item.action ? { click: item.action } : {}">
                    <v-icon class="mr-1">{{ item.icon }}</v-icon>
                    <span>{{ $t(item.title) }}</span>
                  </v-btn>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-menu open-on-click offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon class="mr-1">mdi-account</v-icon>
                {{ currentUser.data.username }}
                <v-icon class="mr-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <div v-for="item in userItems" :key="item.title">
                <v-list-item v-if="item.show">
                  <v-btn text :to="item.to" v-on="item.action ? { click: item.action } : {}">
                    <v-icon class="mr-1">{{ item.icon }}</v-icon>
                    <span>{{ $t(item.title) }}</span>
                  </v-btn>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
      </div>
      <v-btn v-if="hasOriginalId" icon @click="signInAsRestore">
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-menu open-on-click offset-y bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <country-flag :country='getFlag($i18n.locale)' size='normal' class="mb-0"/>
            <v-icon class="mr-1">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="lang in $i18n.availableLocales" :key="lang">
            <v-btn text block @click="$i18n.locale = lang">
              <country-flag :country='getFlag(lang)' size='normal' class="mb-0"/>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer>
      <v-row class="pt-2 mb-0 align-center justify-center">
        <p class="pb-0 mb-0">
          <a href="mailto:admin@zirkel-des-phoenix.de">{{ $t('general.misc.contact') }}</a>
        </p>
        <v-divider vertical class="mx-3"></v-divider>
        <p class="pb-0 mb-0">
          <a href="https://discord.gg/bwmbCJvRup" target="_blank">Discord</a>
        </p>
        <v-divider vertical class="mx-3"></v-divider>
        <p class="pb-0 mb-0">
          <a href="https://raider.io/guilds/eu/blackrock/Zirkel%20des%20Phoenix" target="_blank">Raider.io</a>
        </p>
        <v-divider vertical class="mx-3"></v-divider>
        <p class="pb-0 mb-0">
          <a href="https://www.warcraftlogs.com/guild/id/665023" target="_blank">Warcraft Logs</a>
        </p>
        <v-divider vertical class="mx-3"></v-divider>
        <p class="pb-0 mb-0">
          <a href="https://wowaudit.com/eu/blackrock/zirkel-des-phoenix" target="_blank">Wow Audit</a>
        </p>
        <v-divider vertical class="mx-3"></v-divider>
        <p class="pb-0 mb-0">© 2024</p>
      </v-row>
    </v-footer>
    <sim-settings-dialog/>
  </v-app>
</template>

<script>
import MessageService from "@/services/message.service";
import SimSettingsDialog from "@/components/SimSettingsDialog";
import CountryFlag from 'vue-country-flag'
import Utilities from "@/util/Utilities";

export default {
  name: 'App',
  components: {SimSettingsDialog, CountryFlag},
  data() {
    return {
      menu: false,
      drawer: false,
      title: process.env.VUE_APP_PROJECT_TITLE
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    appConfig(){
      return this.$store.getters["data/appConfig"];
    },
    showRegister() {
      return this.appConfig && this.appConfig.find(x => x.key === "ENABLE_REGISTRATION") && this.appConfig.find(x => x.key === "ENABLE_REGISTRATION").value === "true";
    },
    isNoGenericUser(){
      return this.currentUser && !this.currentUser.data.isGeneric;
    },
    showUserManagement() {
      return this.currentUser && this.currentUser.rights?.includes("EDIT_USER_DATA");
    },
    showCharacterManagement() {
      return this.currentUser && this.currentUser.rights?.includes("EDIT_CHARACTER_DATA");
    },
    showAdminPanel() {
      return this.currentUser && this.currentUser.rights?.includes("READ_ADMIN_PANEL");
    },

    showManagement() {
      return this.showUserManagement || this.showCharacterManagement || this.showAdminPanel;
    },
    hasOriginalId() {
      return this.currentUser && !!this.currentUser.originalId;
    },
    allowSim() {
      return this.currentUser && this.currentUser.rights?.includes("ALLOW_SIM");
    },
    menuItems() {
      return [
        {title: 'information.title', icon: "mdi-information", to: "information", action: null, show: this.currentUser, color: "menu"},
        {title: 'characters.title', icon: "mdi-account-group", to: "characters", action: null, show: this.currentUser, color: "menu"},
        {title: 'simulation.title', icon: "mdi-database", to: "simulation", action: null, show: this.allowSim, color: "menu"},
        {title: 'raids.title', icon: "mdi-view-dashboard", to: "raids", action: null, show: this.currentUser, color: "menu"},
        {title: 'loot.title',icon: "mdi-sack",to: "loot-management",action: null, show: this.currentUser,color: "menu"},
        {title: 'sign_in.title', icon: "mdi-login", to: "sign-in", action: null, show: !this.currentUser, color: "primary"},
        {title: 'register.title', icon: "mdi-account-plus", to: "register", action: null, show: !this.currentUser && this.showRegister, color: "primary"}
      ]
    },
    managementItems(){
      return [
        {title: 'management.user.title', icon: "mdi-account-box-multiple", to: "user-management", action: null, show: this.showUserManagement},
        {title: 'character_management.title', icon: "mdi-account-group", to: "character-management", action: null, show: this.showCharacterManagement},
        {title: 'management.admin.title', icon: "mdi-shield-crown", to: "admin", action: null, show: this.showAdminPanel}
      ]
    },
    userItems() {
      return [
        {title: 'user_account.title', icon: "mdi-account", to: "user-account", action: null, show: true},
        {title: 'user_characters.title', icon: "mdi-account-group", to: "user-characters", action: null, show: this.isNoGenericUser},
        {title: 'simulation.settings', icon: "mdi-tune", to: null, action: this.openSimSettings, show: this.showAdminPanel},
        {title: 'general.actions.sign_out', icon: "mdi-logout-variant", to: undefined, action: this.logOut, show: true}
      ]
    }
  },
  mounted() {
    MessageService.initialize(this.$toast);
    Utilities.initialize(this.$store);
  },
  methods: {
    toHomeView(){
      this.$router.push("/home");
    },
    logOut() {
      this.$store.dispatch('auth/signOut');
      if (this.$route.path !== '/sign-in') {
        this.$router.push('/sign-in');
      }
    },
    signInAsRestore() {
      this.$store.dispatch("auth/signInAsRestore", this.currentUser?.originalId).then(user => {
        MessageService.success(`Returned to user '${user.data.username}'`);
      }, (error) => {
        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      });
    },
    openSimSettings() {
      this.$root.$emit('simSettingsOpen');
    },
    getFlag(lang) {
      if (lang === "en") {
        return "us";
      } else {
        return lang;
      }
    }
  },
  watch: {
    '$i18n.locale': function (newVal) {
      localStorage.setItem("lang", newVal);
    }
  }
};
</script>

<style lang="scss">
$very_very_much_truthier: false;
$useUselessGlowEffect: $very_very_much_truthier; //the truthier the funnier

$class_colors: (
    mage: (63, 199, 235),
    shaman: (0, 112, 221),
    druid: (255, 124, 10),
    hunter: (170, 211, 114),
    death-knight: (196, 30, 58),
    demon-hunter: (163, 48, 201),
    evoker: (51, 147, 127),
    monk: (0, 255, 152),
    paladin: (244, 140, 186),
    rogue: (255, 244, 104),
    warlock: (135, 136, 238),
    priest: (255, 255, 255),
    warrior: (198, 155, 109)
);

@each $class, $rgb in $class_colors {
  .#{$class} {
    color: rgb($rgb...);
  }
  .#{$class}-bg {
    background: rgba(nth($rgb, 1), nth($rgb, 2), nth($rgb, 3), 0.25);
  }
  .v-application .#{$class}--text {
    color: rgb($rgb...) !important;
    caret-color: rgb($rgb...) !important;
  }
  .#{$class}-border {
    border-color: rgba(nth($rgb, 1), nth($rgb, 2), nth($rgb, 3), 0.25) !important;
  }
}

html {
  overflow: auto !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.v-main {
  padding: 100px 0 0 !important;
}

.v-snack {
  margin-top: 60px;
}

@mixin class-item {
  text-shadow: black 0 0 3px;
  -webkit-font-smoothing: none;
}


.theme--light .priest {
  @include class-item;
}

.glow {
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
@if $useUselessGlowEffect {
  .v-btn--active {
    &:before {
      opacity: 0 !important;
    }

    animation: glow 1s ease-in-out infinite alternate !important; //very important
  }

  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e64500, 0 0 40px #e64500, 0 0 50px #e64500, 0 0 60px #e64500, 0 0 70px #e64500;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff7c4d, 0 0 40px #ff7c4d, 0 0 50px #ff7c4d, 0 0 60px #ff7c4d, 0 0 70px #ff7c4d, 0 0 80px #ff7c4d;
    }
  }
}

.raid-item {
  border-radius: 5px;
  border: 3px solid silver;
  height: 40px;
  width: 40px;
}

.noHover{
  pointer-events: none;
}

.equip-image{
  height: 56px;
  width: 56px;
  border-radius: 5px;
  border: 2px solid white;
  background: black;
}
</style>
