<template>
  <div>
    <v-row class="ma-1">
      <span v-if="eventCharacters.length === 0" class="pa-2">
        <v-icon>mdi-close-circle</v-icon>
        {{ $t('group_setup.no_characters_selected') }}
      </span>
      <v-col v-for="role in roleColumns" :key="role.name">
        <p class="mb-1 font-weight-bold text-center">
          <role-avatar :size="20" :role="role.name"></role-avatar>
          {{ role.name }}
        </p>
        <v-divider class="pb-1"/>
        <draggable class="div" group="people" v-model="role.characters" @change="elementDrag($event, role)"
                   :disabled="!selectionChangeAllowed">
          <div class="d-flex planner-char-entry px-2" v-for="roleCharacter in role.characters" :key="roleCharacter.id"
               @click="selectionChangeAllowed ? roleCharacter.selected = !roleCharacter.selected : {}">
            <class-avatar :type="'class'" :value="roleCharacter.class.toLowerCase()" :size="18" class="flex-grow-0"/>
            <div class="flex-grow-1 font-weight-medium" :class="roleCharacter.class.toLowerCase().replaceAll(' ', '-')">
              {{ roleCharacter.character.name }}
            </div>
            <div class="flex-grow-0">
              <v-icon small :color="roleCharacter.selected ? 'green' : 'red'">
                {{ roleCharacter.selected ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </div>
          </div>
        </draggable>
      </v-col>
    </v-row>
    <v-expansion-panels v-if="additionAllowed">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary lighten-1">
          {{  $t("loot.management.new_entry.existing_characters") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          <v-row class="pa-2 align-end">
            <v-col cols="6">
              <v-autocomplete :items="filteredGuildCharacters" v-model="addition.character" :label="$t('general.misc.character')" hide-details return-object
                              :filter="Utilities().searchNormalizedCharacter" @change="selectCharacter" item-text="display">
                <template v-slot:selection="{ item }">
                  <p class="font-weight-medium mb-0">
                    <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{item.display}}</span>
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <p class="font-weight-medium mb-0">
                    <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{item.display}}</span>
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-autocomplete :label="$t('general.misc.role')" :items="defaultRoles" v-model="addition.role" hide-details return-object>
                <template v-slot:selection="{ item }">
                  <p class="font-weight-medium mb-0">
                    <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                    <span>{{ item }}</span>
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <p class="font-weight-medium mb-0">
                    <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                    <span>{{ item }}</span>
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-btn @click="addCharacterToEvent()" icon :disabled="addition.role === null && addition.character === null" hide-details>
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary lighten-1">
          {{  $t("loot.management.new_entry.create_characters") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          <v-row class="pa-2 align-end">
            <v-col cols="3">
              <v-autocomplete label="Realm" :items="realmList" item-text="name" v-model="newCharacter.realm" required hide-details return-object></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="$t('general.misc.name')" v-model="newCharacter.name" required hide-details clearable></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete :label="$t('general.misc.state')" :items="states" item-text="name" v-model="newCharacter.state" required hide-details return-object></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete :label="$t('general.misc.default_role')" :items="defaultRoles" v-model="newCharacter.defaultRole" hide-details return-object>
                <template v-slot:selection="{ item }">
                  <p class="font-weight-medium mb-0">
                    <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                    <span>{{ item }}</span>
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <p class="font-weight-medium mb-0">
                    <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                    <span>{{ item }}</span>
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-btn @click="addCharacterToDatabase()" icon :disabled="newCharacter.state === null || newCharacter.defaultRole === null || newCharacter.realm === null || newCharacter.name === null || newCharacter.name.length === 0">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>

</template>

<script>
import draggable from 'vuedraggable'

import RoleAvatar from "@/components/RoleAvatar";
import ClassAvatar from "@/components/ClassAvatar";
import MessageService from "@/services/message.service";
import CharacterService from "@/services/character.service";
import Constants from "@/util/constants";
import Utilities from "@/util/Utilities";

export default {
  name: "GroupSetup",
  props: ["event", "entries", "selectionChangeAllowed", "additionAllowed"],
  components: {draggable, RoleAvatar, ClassAvatar},
  data() {
    return {
      roleColumns: [],
      databaseCharacters: [],
      eventCharacters: [],
      defaultRoles: Constants.ROLES,
      addition: {
        character: null,
        role: null
      },
      realmList: this.$store.getters["data/realmList"],
      states: Constants.STATES,
      newCharacter: {
        name: "",
        realm: null,
        state: "Gast",
        defaultRole: ""
      },
      characterToAdd: null,
      notExistingCharactersFromAddon: [],
      importDataFromAddon: ""
    }
  },
  mounted() {
    this.role = Constants.ROLES[0];

    CharacterService.getCharacterList().then(data => {
      this.databaseCharacters = data;
    });

    if (this.entries) {
      this.eventCharacters = this.entries;
    } else if(this.event){
      this.eventCharacters = this.event.details.signups;
    }

    this.updateRoleColumns();

    this.$root.$on("updateEvent", event => {
      this.eventCharacters = event.details.signups;
      this.updateRoleColumns();
    });
  },
  methods: {
    Utilities() {
      return Utilities
    },
    updateRoleColumns() {
      this.roleColumns = Constants.COMPLETE_ROLES.filter(r => this.eventCharacters.filter(c => c.role === r).length > 0).map(role => ({
        name: role,
        characters: this.filterCharacters(role)
      }));
    },
    elementDrag(event, column) {
      if (event.added) {
        event.added.element["role"] = column.name;
      }
      this.updateRoleColumns();
    },
    filterCharacters(role) {
      const reorderSelected = true;
      return this.eventCharacters.filter(c => c.role === role).sort((a, b) => {
        let selectedOrder = b.selected - a.selected;
        let classOrder = a.class.localeCompare(b.class);
        let nameOrder = a.character.name.localeCompare(b.character.name);
        return (reorderSelected && selectedOrder) || classOrder || nameOrder;
      });
    },
    selectCharacter(){
      if (this.addition.character.defaultRole) {
        this.addition.role = this.addition.character.defaultRole;
      }
    },
    addCharacterToEvent() {
      let character = this.addition.character;
      let role = this.addition.role;

      let found = this.event.details.signups.find(x => x.id === character.id);
      if (!found) {
        this.event.details.signups.push({
          id: character.id,
          character: {
            name: character.name,
            realm: character.realm
          },
          class: character.class,
          role: role,
          selected: true
        });
        this.addition.character = null;
        this.updateRoleColumns();
      } else {
        MessageService.warn("Duplicated entry is not possible");
      }
    },
    addCharacterToDatabase(){
      if(this.databaseCharacters.find(x => x.name.toLowerCase() === this.newCharacter.name.toLowerCase() && x.realm === this.newCharacter.realm["name"])){
        MessageService.error("This character is already existing within database");
      } else {
        CharacterService.addCharacter({
          name: this.newCharacter.name.toLowerCase(),
          realm: this.newCharacter.realm["slug"],
          state: this.newCharacter.state,
          defaultRole: this.newCharacter.defaultRole,
          isManualAdded: true
        }).then(data => {
          if(data){
            this.databaseCharacters.push(data);
            this.event.details.signups.push({
              id: data.id,
              character: {
                name: data.name,
                realm: data.realm
              },
              class: data.class,
              role: this.newCharacter.defaultRole,
              selected: true
            });

            this.newCharacter.name = "";
            this.updateRoleColumns();
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("updateEvent");
  },
  computed: {
    filteredGuildCharacters() {
      let signups = [];
      if (this.event) {
        signups = this.event.details.signups.map(x => x.id);
      }
      return this.databaseCharacters.filter(x => !signups.includes(x.id)).sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  watch: {
    "addition.character": function(newVal) {
    }
  }
}
</script>

<style scoped lang="scss">
.planner-new-raid .planner-char-entry {
  &:hover {
    background-color: #333333;
    border-radius: 4px;
    cursor: pointer;
  }
}

.planner-char-entry {
  gap: 0 8px;
}
</style>