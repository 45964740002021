<template>
  <div>
    <h3>
      {{ $t('management.admin.title') }}
    </h3>
    <v-divider></v-divider>
    <v-tabs right v-model="tab" class="mt-2">
      <v-tab key="config-tab">{{ $t('management.admin.config.title') }}</v-tab>
      <v-tab key="logs-tab">{{ $t('management.admin.logs.title') }}</v-tab>
      <v-tab key="util-tab">{{ $t('management.admin.utility.title') }}</v-tab>
      <v-tab key="screenshots-tab">{{ $t('management.admin.screenshots.title') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item key="config-tab">
        <v-form class="pa-3">
          <v-container>
            <v-row class="pa-2" v-for="config in getConfigByType(['number', 'text'])" :key="config.key">
              <v-text-field :type="config.type" :label="config.key" v-model="config.value"
                            :disabled="!canEdit || config.readonly" hide-details
                            v-if="config.type === 'text' || config.type === 'number'"/>
            </v-row>
            <v-row class="pa-2" v-for="config in getConfigByType(['combo', 'combo-multi'])" :key="config.key">
              <v-autocomplete :multiple="config.type === 'combo-multi'" :label="config.key"
                              :items="getConfigComboValues(config)" @change="changeComboValue(config)" return-object
                              v-model="config.mappedValue" :disabled="!canEdit || config.readonly" hide-details
                              v-if="config.type === 'combo' || config.type === 'combo-multi'"></v-autocomplete>
            </v-row>
            <v-row class="pa-2" v-for="config in getConfigByType(['date'])" :key="config.key">
              <date-input-text-field :identifier="config.key" :model="config.value" :label="config.key"/>
            </v-row>
            <v-row class="pa-2" v-for="config in getConfigByType(['checkbox'])" :key="config.key">
              <v-switch v-model="config.mappedValue" :label="config.key" :disabled="!canEdit || config.readonly" inset
                        hide-details v-if="config.type === 'checkbox'" @change="changeCheckboxValue(config)"></v-switch>
            </v-row>
            <v-row class="pa-2 justify-end">
              <v-btn @click="restoreAppConfig" class="mr-1" :disabled="!canEdit">
                <v-icon>mdi-refresh</v-icon>
                {{ $t('general.actions.restore') }}
              </v-btn>
              <v-btn @click="saveAppConfig" :disabled="!canEdit">
                <v-icon>mdi-save</v-icon>
                {{ $t('general.actions.save') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <v-tab-item key="logs-tab">
        <v-row class="ma-2 align-center">
          <v-text-field v-model="logs.search" append-icon="mdi-magnify" type="text" class="mr-2"
                        :label="$t('general.misc.search')" clearable hide-details></v-text-field>
          <v-autocomplete :items="logs.levels" v-model="logs.filterOnLevel" :label=" $t('management.admin.logs.levels')"
                          hide-details class="mr-2"
                          return-object clearable :filter="Utilities().searchNormalized">
            <template v-slot:selection="{ item }">
              <p class="font-weight-medium mb-0">
                <v-icon class="mr-1" color="grey" v-if="item === 'DEBUG'">mdi-bug</v-icon>
                <v-icon class="mr-1" color="info" v-if="item === 'INFO'">mdi-information-outline</v-icon>
                <v-icon class="mr-1" color="warning" v-if="item === 'WARN'">mdi-alert-outline</v-icon>
                <v-icon class="mr-1" color="error" v-if="item === 'ERROR'">mdi-close-circle-outline</v-icon>
                {{ item }}
              </p>
            </template>
            <template v-slot:item="{ item }">
              <p class="font-weight-medium mb-0">
                <v-icon class="mr-1" color="grey" v-if="item === 'DEBUG'">mdi-bug</v-icon>
                <v-icon class="mr-1" color="info" v-if="item === 'INFO'">mdi-information-outline</v-icon>
                <v-icon class="mr-1" color="warning" v-if="item === 'WARN'">mdi-alert-outline</v-icon>
                <v-icon class="mr-1" color="error" v-if="item === 'ERROR'">mdi-close-circle-outline</v-icon>
                {{ item }}
              </p>
            </template>
          </v-autocomplete>
          <v-autocomplete :items="logs.services" v-model="logs.filterOnService" label="Service" hide-details
                          return-object clearable :filter="Utilities().searchNormalized">
          </v-autocomplete>
        </v-row>
        <v-data-table :headers="logs.headers" :items="logs.list" item-key="id"
                      :search="logs.search" :loading="logs.isLoading" sort-by="timestamp" sort-desc
                      :page.sync="logs.page" :items-per-page="logs.itemsPerPage" hide-default-footer
                      @page-count="logs.pageCount = $event" :custom-filter="Utilities().searchNormalized">
          <template v-slot:item.level="{ item }">
            <v-row class="pa-2 align-center">
              <v-icon class="mr-1" color="grey" v-if="item.level === 'DEBUG'">mdi-bug</v-icon>
              <v-icon class="mr-1" color="info" v-if="item.level === 'INFO'">mdi-information-outline</v-icon>
              <v-icon class="mr-1" color="warning" v-if="item.level === 'WARN'">mdi-alert-outline</v-icon>
              <v-icon class="mr-1" color="error" v-if="item.level === 'ERROR'">mdi-close-circle-outline</v-icon>
              {{ item.level }}
            </v-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2 mx-2 px-4">
          <v-pagination class="ma-2" v-if="logs.list?.length > 0" v-model="logs.page"
                        :length="logs.pageCount"></v-pagination>
        </div>
      </v-tab-item>
      <v-tab-item key="util-tab">
        <v-expansion-panels class="pa-3" multiple v-model="utility.expanded">
          <v-expansion-panel class="secondary lighten-1">
            <v-expansion-panel-header>
              <h3>{{ $t('management.admin.utility.data.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p><i>{{ $t('management.admin.utility.sync.talent.description') }}</i></p>
              <v-row class="px-3 pb-3 align-center">
                <v-text-field :label="$t('general.misc.game_version')" v-model="gameVersion" class="mr-2"
                              :disabled="!canEdit" hide-details/>
                <v-checkbox class="mr-2" :label="$t('management.admin.utility.sync.talent.force_update')"
                            v-model="forceTalentUpdate" :disabled="!canEdit" hide-details></v-checkbox>
                <v-btn @click="syncTalents" :disabled="!canEdit && gameVersion?.length === 0"
                       color="primary" class="mt-3">
                  {{ $t('management.admin.utility.sync.talent.action') }}
                </v-btn>
              </v-row>
              <v-divider class="my-4 px-2"></v-divider>
              <p><i>{{ $t('management.admin.utility.sync.instance.description') }}</i></p>
              <v-row class="px-3 pb-3 align-center">
                <v-text-field :label="$t('management.admin.utility.sync.instance.name')" v-model="instance" class="mr-2"
                              hide-details
                              :disabled="!canEdit"/>
                <v-btn @click="addInstance" :disabled="!canEdit && instance?.length === 0" color="primary" class="mt-3">
                  {{ $t('management.admin.utility.sync.instance.action') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="secondary lighten-1">
            <v-expansion-panel-header>
              <h3>{{ $t('management.admin.utility.character_actions.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p><i>{{ $t('management.admin.utility.character_actions.transfer_data.description') }}</i></p>
              <v-row class="px-3 pb-3 align-center">
                <v-autocomplete :items="filterInactiveCharacters" v-model="utility.originCharacter"
                                :label="$t('management.admin.utility.character_actions.transfer_data.origin')"
                                hide-details return-object clearable
                                :filter="Utilities().searchNormalizedCharacter" class="mr-2" item-text="display">
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-autocomplete :items="filterActiveCharacters" v-model="utility.newCharacter"
                                :label="$t('management.admin.utility.character_actions.transfer_data.new')" hide-details
                                return-object clearable
                                :filter="Utilities().searchNormalizedCharacter" class="mr-2" item-text="display">
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-checkbox class="mr-2" :label="$t('management.admin.utility.character_actions.transfer_data.copy')"
                            v-model="utility.characterCopy" hide-details></v-checkbox>
                <v-btn @click="transferData"
                       :disabled="utility.originCharacter === null || utility.newCharacter === null "
                       color="primary" class="mt-3">
                  {{ $t('management.admin.utility.character_actions.transfer_data.title') }}
                </v-btn>
              </v-row>
              <v-divider class="my-4 px-2"></v-divider>
              <p><i>{{ $t('management.admin.utility.character_actions.activate.title') }}</i></p>
              <v-row class="px-3 pb-3 align-center">
                <v-autocomplete :items="filterInactiveCharacters" v-model="utility.characterToActivate"
                                :label="$t('management.admin.utility.character_actions.activate.character')"
                                hide-details return-object clearable
                                :filter="Utilities().searchNormalizedCharacter" class="mr-2" item-text="display">
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-autocomplete :label="$t('management.admin.utility.character_actions.activate.state')" class="mr-2"
                                return-object hide-details
                                :items="utility.states" v-model="utility.activationState" clearable></v-autocomplete>
                <v-autocomplete :label="$t('management.admin.utility.character_actions.activate.role')"
                                :items="defaultRoles"
                                v-model="utility.activationRole" hide-details return-object class="mr-2" clearable>
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                      <span>{{ item }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <role-avatar :size="18" :role="item" class="mr-1"></role-avatar>
                      <span>{{ item }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-btn @click="activateCharacter"
                       :disabled="utility.characterToActivate === null || utility.activationRole === null || utility.activationState === null"
                       color="primary" class="mt-3">
                  {{ $t('management.admin.utility.character_actions.activate.title') }}
                </v-btn>
              </v-row>
              <v-divider class="my-4 px-2"></v-divider>
              <p><i>{{ $t('management.admin.utility.character_actions.deactivate.description') }}</i></p>
              <v-row class="px-3 pb-3 align-center">
                <v-autocomplete :items="filterActiveCharacters" v-model="utility.characterToDeactivate"
                                :label="$t('management.admin.utility.character_actions.deactivate.character')"
                                hide-details return-object clearable
                                :filter="Utilities().searchNormalizedCharacter" class="mr-2" item-text="display">
                  <template v-slot:selection="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <p class="font-weight-medium mb-0">
                      <span :class="item?.class?.toLowerCase().replaceAll(' ', '-')">{{ item.display }}</span>
                    </p>
                  </template>
                </v-autocomplete>
                <v-btn @click="deactivateCharacter" :disabled="utility.characterToDeactivate === null"
                       color="primary" class="mt-3">
                  {{ $t('management.admin.utility.character_actions.deactivate.title') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="secondary lighten-1">
            <v-expansion-panel-header>
              <h3>{{ $t('management.admin.utility.loot.title') }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p><i>{{ $t('management.admin.utility.loot.loot_episode.create.description') }}</i></p>
              <v-row class="px-3 align-center">
                <v-text-field :label="$t('char_detail.loot.table.episode')" v-model="lootEpisode" class="mr-2"
                              :disabled="!canEdit"/>
                <v-btn @click="addLootEpisode" :disabled="!canEdit && lootEpisode?.length === 0" color="primary">
                  {{ $t('management.admin.utility.loot.loot_episode.create.action') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item key="screenshot-tab">
        <v-row class="ma-2 align-center">
          <v-text-field class="pa-2" v-model="screenshots.search" append-icon="mdi-magnify"
                        :label="$t('general.misc.search')" clearable hide-details></v-text-field>
          <v-chip :color="!screenshots.filterOnArchived ? 'dark-gray' : screenshots.archivedFilter ? 'primary' : 'info'"
                  text-color="white" class="mt-3 ml-2 mr-2"
                  @contextmenu="$event.preventDefault(); screenshots.archivedFilter = !screenshots.archivedFilter"
                  @click="screenshots.filterOnArchived = !screenshots.filterOnArchived">
            <v-avatar left>
              <v-icon>{{ screenshots.archivedFilter ? 'mdi-archive-plus' : 'mdi-archive-minus' }}</v-icon>
            </v-avatar>
            <span v-if="screenshots.archivedFilter">
                {{ $t('management.admin.screenshots.archived') }}
                </span>
            <span v-else>
                {{ $t('management.admin.screenshots.not_archived') }}
              </span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn @click="screenshots.isOpenCreateDialog = true" color="primary" class="mr-1 mt-2">
            <v-icon>mdi-plus-circle</v-icon>
            <span class="ml-1">{{ $t('general.actions.create') }}</span>
          </v-btn>
        </v-row>
        <v-data-table :headers="screenshots.headers" :items="screenshots.list" item-key="id"
                      :search="screenshots.search" :loading="screenshots.isLoading"
                      :page.sync="screenshots.page" :items-per-page="screenshots.itemsPerPage" hide-default-footer
                      @page-count="screenshots.pageCount = $event" :custom-filter="Utilities().searchNormalized">
          <template v-slot:item.description="{ item }">
            <v-row class="pa-2 align-center" dense v-if="item.edit">
              <v-text-field v-model="item.description"></v-text-field>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="updateDescription(item)" icon class="mb-1"
                         color="primary" v-on="on">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('management.admin.screenshots.save_description') }}</span>
              </v-tooltip>
            </v-row>
            <v-row class="pa-2 align-center" dense v-else>
              <span>{{ item.description }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="item.edit = !item.edit" icon class="mb-1"
                         color="primary" v-on="on">
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('management.admin.screenshots.update_description') }}</span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:item.isArchived="{ item }">
            <v-chip small :color="item.isArchived ? 'primary' : 'info'" text-color="white" class="mr-1 mb-1">
              <v-avatar left>
                <v-icon small>{{ item.isArchived ? 'mdi-archive-plus' : 'mdi-archive-minus' }}</v-icon>
              </v-avatar>
              <span v-if="item.isArchived">
                  {{ $t('management.admin.screenshots.archived') }}
                </span>
              <span v-else>
                  {{ $t('management.admin.screenshots.not_archived') }}
                </span>
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click=" screenshots.preview = item.content; screenshots.isOpenPreviewDialog = true;" icon
                       color="primary" v-on="on">
                  <v-icon>mdi-image</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.admin.screenshots.preview') }}</span>
            </v-tooltip>

            <v-tooltip top v-if="!item.isArchived">
              <template v-slot:activator="{ on }">
                <v-btn @click="updateArchiveState(item, true)" icon
                       color="primary" v-on="on">
                  <v-icon>mdi-archive-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.admin.screenshots.archive') }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.isArchived">
              <template v-slot:activator="{ on }">
                <v-btn @click="updateArchiveState(item, false)" icon
                       color="primary" v-on="on">
                  <v-icon>mdi-archive-minus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.admin.screenshots.activate') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteScreenshot(item)" icon
                       color="primary" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('management.admin.screenshots.remove') }}</span>
            </v-tooltip>

          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-if="screenshots.list.length > 0" v-model="screenshots.page"
                        :length="screenshots.pageCount"></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="screenshots.isOpenCreateDialog" max-width="400px">
      <v-card>
        <v-toolbar flat dense color="primary">
          <v-icon class="mr-1">mdi-image</v-icon>
          {{ $t('management.admin.screenshots.create') }}
        </v-toolbar>
        <v-card-text class="pa-3">
          <v-text-field :label="$t('management.admin.screenshots.table.description')"
                        v-model="screenshots.dialogModel.screenshotDescription" class="mr-2"/>
          <v-file-input :label="$t('management.admin.screenshots.table.image')"
                        v-model="screenshots.dialogModel.screenshot" accept="image/png, image/jpeg"
                        class="mr-2"></v-file-input>
        </v-card-text>
        <v-card-actions class="pa-3 justify-end">
          <v-btn class="mr-2" @click="screenshots.isOpenCreateDialog = false">
            {{ $t('general.actions.cancel') }}
          </v-btn>
          <v-btn @click="uploadScreenshot()"
                 :disabled="screenshots.dialogModel.screenshot === null || screenshots.dialogModel.screenshotDescription === ''"
                 color="primary">
            {{ $t('management.admin.screenshots.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="screenshots.isOpenPreviewDialog" max-width="1200px">
      <v-card class="mx-auto">
        <v-img
            :src="screenshots.preview"
            contain>
          <v-app-bar flat color="transparent">
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click="screenshots.isOpenPreviewDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DataService from "@/services/data.service";
import MessageService from "@/services/message.service";
import SyncService from "@/services/sync.service";
import CharacterService from "@/services/character.service";
import Constants from "@/util/constants";
import Utilities from "@/util/Utilities";
import RoleAvatar from "@/components/RoleAvatar.vue";
import DateInputTextField from "@/components/controls/DateInputTextField.vue";

export default {
  name: "Admin",
  components: {DateInputTextField, RoleAvatar},
  data() {
    return {
      tab: null,
      configs: [],
      gameVersion: '',
      forceTalentUpdate: false,
      instance: '',
      lootEpisode: '',
      defaultRoles: Constants.ROLES,
      logs: {
        filterOnLevel: null,
        filterOnService: null,
        services: [],
        startDate: new Date(new Date().setDate(new Date().getDate() - 10)),
        endDate: new Date(),
        levels: ["DEBUG", "INFO", "WARN", "ERROR"],
        search: "",
        isLoading: true,
        itemsPerPage: 100,
        page: 1,
        pageCount: 0,
        headers: [
          {
            text: "Level", value: "level", width: '100px',
            filter: value => {
              return this.logs.filterOnLevel === null || value === this.logs.filterOnLevel;
            }
          },
          {
            text: "Service", value: "service",
            filter: value => {
              return this.logs.filterOnService === null || value === this.logs.filterOnService;
            }
          },
          {text: this.$t('management.admin.logs.message'), value: "message"},
          {text: this.$t('management.admin.logs.timestamp'), value: "timestamp", width: '180px'},
        ]
      },
      screenshots: {
        isOpenCreateDialog: false,
        isOpenPreviewDialog: false,
        preview: null,
        dialogModel: {
          screenshot: null,
          screenshotDescription: ""
        },
        list: [],
        isLoading: true,
        search: "",
        itemsPerPage: 100,
        page: 1,
        pageCount: 0,
        filterOnArchived: false,
        archivedFilter: false,
        headers: [
          {text: this.$t('management.admin.screenshots.table.description'), value: "description"},
          {
            text: this.$t('management.admin.screenshots.archived'), value: "isArchived",
            filter: value => {
              return !this.screenshots.filterOnArchived || value === this.screenshots.archivedFilter;
            }
          },
          {text: this.$t('general.timestamps.created_at'), value: "createdAt"},
          {text: this.$t('general.timestamps.updated_at'), value: "updatedAt"},
          {text: this.$t('general.misc.actions'), value: "actions"}
        ]
      },
      utility: {
        expanded: [0, 1, 2, 3],
        characters: [],
        originCharacter: null,
        newCharacter: null,
        characterCopy: false,
        characterToActivate: null,
        states: Constants.STATES,
        activationState: 'None',
        activationRole: '',
        characterToDeactivate: null,
      }
    }
  },
  async mounted() {
    this.restoreAppConfig();

    if (!this.currentUser?.rights.includes("READ_ADMIN_PANEL") && this.$route.path !== '/home') {
      await this.$router.push('/home');
    } else {
      this.utility.characters = await CharacterService.getCharacterList(true, true);

      this.loadLogs();

      DataService.getImages(false).then(images => {
        this.screenshots.list = images.map(image => ({
          ...image,
          edit: false,
          createdAt: `${new Date(image["createdAt"]).toLocaleDateString()} ${new Date(image["createdAt"]).toLocaleTimeString()}`,
          updatedAt: `${new Date(image["updatedAt"]).toLocaleDateString()} ${new Date(image["updatedAt"]).toLocaleTimeString()}`,
        }))
        this.screenshots.isLoading = false;
      });

      this.$root.$on("date_input_change", data => {
        console.log(data);
        console.log(this.configs);
        let foundConfig = this.configs.find(x => x.key === data.identifier);
        if(foundConfig){
          foundConfig.value = data.value;
        }
      });
    }
  },
  methods: {
    loadLogs() {
      this.logs.isLoading = true;
      DataService.getLogs({
        start: this.logs.startDate,
        end: this.logs.endDate
      }).then(logs => {
        this.logs.list = logs.map(log => ({
          ...log,
          level: log.level.toUpperCase(),
          timestamp: `${new Date(log["timestamp"]).toLocaleDateString()} ${new Date(log["timestamp"]).toLocaleTimeString()}`
        }));
        this.logs.services = [...new Set(this.logs.list.filter(x => x.service).map(x => x.service))].sort((a, b) => a.localeCompare(b));
        this.logs.isLoading = false;
      });
    },
    Utilities() {
      return Utilities
    },
    getConfigByType(types) {
      return this.configs.filter(x => types.includes(x.type)).sort((a, b) => a.type.localeCompare(b.type));
    },
    getConfigComboValues(config) {
      let relatedConfig = this.configs.find(x => x.key === config.relatedTo);
      return relatedConfig.value.split(";");
    },
    changeComboValue(config) {
      if (config.type === 'combo-multi') {
        config.value = config.mappedValue.join(";");
      } else {
        config.value = config.mappedValue;
      }
    },
    changeCheckboxValue(config) {
      config.value = config.mappedValue.toString();
    },
    restoreAppConfig() {
      this.configs = JSON.parse(JSON.stringify(this.$store.getters["data/appConfig"]));
    },
    saveAppConfig() {
      this.$store.dispatch("data/updateAppConfig", this.configs).then(() => {
        MessageService.success("Successfully updated app config list.");
      }).catch(err => {
        MessageService.error("Update failed. Error:" + err.message);
      });
    },
    async syncTalents() {
      await SyncService.syncTalents(this.gameVersion, this.forceTalentUpdate);
    },
    addInstance() {
      SyncService.addInstance(this.instance).then(() => {
        this.$store.dispatch("data/getAppConfig");
        this.configs = JSON.parse(JSON.stringify(this.$store.getters["data/appConfig"]));
      });
    },
    addLootEpisode() {
      let episodes = this.configs.find(x => x.key === "LOOT_EPISODES");
      if (episodes) {
        if (episodes.length > 0) {
          episodes.value = this.lootEpisode;
        } else {
          episodes.value = episodes.value + ";" + this.lootEpisode;
        }

        this.$store.dispatch("data/updateAppConfig", this.configs).then(() => {
          MessageService.success("Successfully added loot episode.");
          this.$store.dispatch("data/getAppConfig");
          this.configs = JSON.parse(JSON.stringify(this.$store.getters["data/appConfig"]));
        }).catch(err => {
          MessageService.error("Update of loot episode failed. Error:" + err.message);
        });
      }
    },
    transferData() {
      CharacterService.transferCharacterData(this.utility.originCharacter.id, this.utility.newCharacter.id, this.utility.characterCopy).then(async data => {
        if (data) {
          this.utility.originCharacter = null;
          this.utility.newCharacter = null;
          this.utility.characters = await CharacterService.getCharacterList(true, true);
        }
      });
    },
    activateCharacter() {
      CharacterService.updateCharacter(this.utility.characterToActivate.id, {
        state: this.utility.activationState,
        defaultRole: this.utility.activationRole,
        isActive: true
      }).then(data => {
        if (data !== null) {
          this.utility.characterToActivate = null;
          this.utility.activationRole = '';
          this.utility.activationState = 'None';

          let index = this.utility.characters.findIndex(x => x.id === data["id"]);
          if (index !== -1) {
            this.utility.characters[index] = data;
            this.$forceUpdate();
          }
        }
      });
    },
    deactivateCharacter() {
      CharacterService.updateCharacter(this.utility.characterToDeactivate.id, {
        state: '',
        defaultRole: '',
        isActive: false
      }).then(data => {
        if (data !== null) {
          this.utility.characterToDeactivate = null;
          this.utility.activationRole = '';
          this.utility.activationState = 'None';

          let index = this.utility.characters.findIndex(x => x.id === data["id"]);
          if (index !== -1) {
            this.utility.characters[index] = data;
            this.$forceUpdate();
          }
        }
      });
    },
    uploadScreenshot() {
      const reader = new FileReader();
      reader.readAsDataURL(this.screenshots.dialogModel.screenshot);
      reader.onload = (() => {
        DataService.createImage(this.screenshots.dialogModel.screenshotDescription, reader.result).then(data => {
          if (data) {
            this.screenshots.isOpenCreateDialog = false;
            MessageService.success("Screenshot successfully uploaded.");
          }
        });
      });
      reader.onerror = (() => {
        MessageService.error("Could not upload screenshot!");
      });
    },
    updateArchiveState(screenshot, isArchived) {
      DataService.updateImage(screenshot.id, {
        isArchived: isArchived
      }).then(data => {
        if (data) {
          screenshot.isArchived = isArchived;
          MessageService.success("Screenshot successfully updated.");
          this.$forceUpdate();
        }
      });
    },
    updateDescription(screenshot) {
      DataService.updateImage(screenshot.id, {
        description: screenshot.description
      }).then(data => {
        if (data) {
          screenshot.edit = false;
          MessageService.success("Screenshot successfully updated.");
          this.$forceUpdate();
        }
      });
    },
    deleteScreenshot(screenshot) {
      DataService.deleteImage(screenshot.id).then(data => {
        if (data) {
          MessageService.success("Screenshot successfully deleted.");
          let index = this.utility.screenshots.findIndex(x => x.id === data.id);
          if (index !== -1) {
            this.utility.screenshots.splice(index, 1);
            this.$forceUpdate();
          }
        }
      });
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    appConfig() {
      return this.$store.getters["data/appConfig"];
    },
    canEdit() {
      return this.currentUser && this.currentUser?.rights.includes("EDIT_ADMIN_DATA");
    },
    filterInactiveCharacters() {
      return this.utility.characters.filter(x => x.isActive === false);
    },
    filterActiveCharacters() {
      return this.utility.characters.filter(x => x.isActive === true);
    }
  },
  beforeDestroy() {
    this.$root.$off('date_input_change');
  }
}
</script>

<style scoped>
</style>
